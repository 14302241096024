.contactContainer {
  height: 100vh;
  background-color: #606ae3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
}

.formButton {
  width: fit-content;
  justify-self: end;
}

.formButtonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .form {
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .card {
    width: 90%;
    height: 75%;
  }
}

.marginTop {
  margin-top: 1em;
}