body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #606ae3;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

code {
  font-family: "Quicksand", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2)!important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}