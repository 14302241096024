.container {
    height: 200px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 80%;
        height: auto;
    }
}

.first {
    height: 30%;
    border-right: 4px solid #343434;
    border-left: 4px solid #343434;
    margin-left: 5%;
    margin-right: 5%;
}

.second {
    height: 100px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px rgba(90, 90, 90, 0.13);

    transform-origin: 50% 0;

    animation: rotateZ 1s infinite alternate;
}

.second > a {
    text-decoration: none;
    color: black;
}

.second:hover {
    animation: zoom 1s infinite alternate ease;
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.05);
    }
}