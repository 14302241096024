.aboutContainer {
    height: 100vh;
    background-color: #3a3e62;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: white;
}

.about {
    color: white;
}

.imageContainer {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image1, .image2, .image3, .image4, .image5, .image6 {
    margin: 1em;
    animation: imageHover 1s infinite alternate;
}

.image2 {
    animation-delay: 200ms;
}
.image3 {
    animation-delay: 400ms;
}
.image4 {
    animation-delay: 600ms;
}
.image5 {
    animation-delay: 800ms;
}
.image6 {
    animation-delay: 1000ms;
}

@keyframes imageHover {
    0% {
    }
    100% {
        transform: scale(1.3);
    }
}

.aboutImage {
    max-width: 300px;
    max-height: 300px;
    height: 20%;
    border-radius: 50%;
}

.content {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    padding: 0 1em 0 1em;

}
