.homeContainer {
  height: 100vh;
  background-color: #606ae3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  color: white;
}

.homeContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcomeLogo {
  max-width: 50%;
  animation: logoAppear 3500ms;
}

@keyframes logoAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcomeDown {
  bottom: 5%;
  color: white;
  opacity: 0;

  animation: appear 2s forwards;
  animation-delay: 3s;
}

.welcomeH1 {
  font-family: monospace;
  position: relative;
  width: max-content;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.welcomeH1::after,
.welcomeH1::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #606ae3;

  animation: typewriter 1s steps(20) forwards;
}

.welcomeH1::after {
  width: 0.125em;
  background-color: white;
  animation: blink steps(24) 1s infinite, typewriter 1s steps(20) forwards;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}